import Home from '@/views/home/index.vue'

export default {
    path: '/system_set',
    component: Home,
    name: 'system_set',
    meta: {
        title: '系统设置',
    },
    children: [{
        path: '/behavior_Log',
        component: () =>
            import('@/views/system_set/behavior_Log.vue'),
        hidden: true,
        name: 'behavior_Log',
        meta: {
            title: '操作员行为日志',
        },
    }
    ]
}
import request from '@/api/request.js'

//登录
export function userLogin(data) {
	return request({
		url: `/v1/User/Login`,
		method: "POST",
		data,
	})
}

export function GetAllUserFunc() {
	return request({
		url: `/v1/User/GetAllFunc`,
		method: "GET",
	})
}

export function GetUserFunc(params) {
	return request({
		url: `/v1/User/GetUserFunc`,
		method: "GET",
		params
	})
}
import request from '@/api/request.js'

//币种监控
export function getConsoleDesk() {
	return request({
		url: `/v1/Console/ChainView`,
		method: "get",
	})
}

//图集信息
export function getHeaderInfo() {
	return request({
		url: `/v1/Console/HeaderInfo`,
		method: "get",
	})
}
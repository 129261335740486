import Vue from 'vue'
import store from '@/store/index.js'
// 权限检查方法
Vue.prototype.$_has = function (button, binding) {
	//判断是否拥有权限
	let isExist=false;
	for (let i = 0; i < button.length; i++) {
		if(button[i].Router==binding.value){
			return isExist=true
		}
	}
	return isExist;
};

/* 权限命令 */
export default {
	install(Vue) {
		Vue.directive('has', {
			inserted: function (el, binding, vnode) {
				// console.log(2229999,binding);
				let button = store.getters.buttons;
				if (!Vue.prototype.$_has(button, binding)) {
					//没有权限则删除元素
					el.parentNode.removeChild(el);
				}
			}
		})
	}

}
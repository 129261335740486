// 本地存储
// export default{
//     get(key){
//       return  JSON.parse(localStorage.getItem(key));
//     },
//     set(key,value){
//         console.log(value);
//         localStorage.setItem(key,JSON.stringify(value));
//     },
//     remove(key){
//         localStorage.removeItem(key);
//     },
//     clear(){
//         localStorage.clearItem();
//     }
// }

export function set(info) {
	console.log(info);
	return localStorage.setItem("userInfo",JSON.stringify(info));
}
export function get(key) {
	return JSON.parse(localStorage.getItem(key));
}
export function remove(key) {
	return localStorage.removeItem(key);
}
// store/permission.js

import {
	MessageBox,
	Message
} from 'element-ui'
import {
	GetUserFunc
} from '@/api/page/login.js'
import Vuex from 'vuex'
import axios from 'axios'
import store from '@/store'
import qs from 'qs'
import {
	setMenu
} from '@/utils/auth'


//存放状态
const state = {
	routes: [],
	addRoutes: [],
	buttons: [],
	menuId: null,
	FuncId: 0
}

//state成员操作
const mutations = {
	SET_ROUTES: (state, routes) => {
		state.addRoutes = routes
		state.routes = routes
	},
	SET_BUTTONS: (state, buttons) => {
		state.buttons = buttons
	},
	SET_MENUID: (state, menuId) => {
		state.menuId = menuId
	},
	SET_FUNCID: (state, FuncId) => {
		state.FuncId = FuncId
	}
}

//异步操作
const actions = {
	getAllMenusByRoles({
		commit,
		dispatch
	}) {
		return new Promise(resolve => {
			GetUserFunc({UserId:store.getters.loginId.UserId}).then((res) => {
				//获取菜单按钮
				dispatch('getMenuButton', res.Data.Functions)
				//递归删除菜单中的按钮
				function filterArr(val) {
					let temp = val.filter(item => item.Type != 1)
					for (let i = 0; i < temp.length; i++) {
						let currentParent = temp[i]; // 获取当前父级元素
						// 初始化该父级元素的子级数组
						currentParent['Children'] = [];
						// 遍历子级数组
						for (let j = 0; j < temp.length; j++) {
							var currentChild = temp[j]; // 获取当前子级元素
							
							if (currentChild.ParentId === currentParent.Id) {
								// 若当前子级元素的parentId等于当前父级元素的id，则将其添加到该父级元素的子级数组中
								currentParent['Children'].push(currentChild);
								
								// 同时，将该子级元素从原始子级数组中移除，以便不重复处理
								temp.splice(j--, 1);
							}
						}
					}
					return temp
				}
				let accessedRoutes = filterArr(res.Data.Functions)
				setMenu(accessedRoutes)

				commit('SET_ROUTES', accessedRoutes)

				resolve(accessedRoutes)
			})
		})
	},
	getMenuId({
		commit
	}, val) {
		return new Promise(resolve => {
			console.log('menuId', val)
		})
	},
	getMenuButton({
		commit,
		dispatch
	}, val) {
		return new Promise(resolve => {
			let button = [];
			//过滤没有按钮的页面
			val.forEach((v) => {
				// if (v.Children && v.Children.length > 0) {
				// 	v.Children.forEach((item) => {
				// 		if(item.Children && item.Children.length > 0&&item.Type==0){
				// 			button= [...button, ...item.Children]
				// 		}
				// 	})
				// }
				if(v.Type==1){
					button.push(v)
				}
			})
			commit('SET_BUTTONS', button)
			resolve(button)
		})
	},
	setMenuId({
		commit
	}, val) {
		return new Promise(resolve => {
			commit('SET_MENUID', val)
			resolve(val)
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}

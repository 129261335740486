import Home from '@/views/home/index.vue'

export default {
    path: '/public_chain_management',
    component: Home,
    name: 'public_chain_management',
    meta: {
        title: '公链管理',
    },
    children: [{
        path: '/public_chain_list',
        component: () =>
            import('@/views/public_chain_management/public_chain_list.vue'),
        hidden: true,
        name: 'public_chain_list',
        meta: {
            title: '公链列表',
        },
    },
    {
        path: '/public_token_chain_list',
        component: () =>
            import('@/views/public_chain_management/public_token_chain_list.vue'),
        hidden: true,
        name: 'public_token_chain_list',
        meta: {
            title: '公链代币列表',
        },
    }
    ]
}
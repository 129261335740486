import Vue from 'vue'
import Router from 'vue-router';

import Login from '../views/login/index.vue'
import Home from '@/views/home/index.vue'
import welcome from '@/views/welcome/index.vue'
import consoleDesk from '@/views/consoleDesk/index.vue'

Vue.use(Router)
//自动注册路由
const requireRoutes = require.context('./modules', false, /.js$/)
const routeMap = requireRoutes.keys().map(route => {
  return requireRoutes(route).default
})
export const constantRouterMap = [...routeMap, ...[
  {
    path: '/',
    name: 'login',
    component: Login
  }, {
    path: '/redirect',
    component: Home,
    name: "重定向",
    hidden: true,
    children: [{
      path: '/redirect/:path(.*)',
      component: () => import('@/views/redirect/index')
    }]
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/welcome',
        name: 'welcome',
        component: welcome,
        meta: {
          title: '欢迎页',
          affix: true
        },
      },
      {
        path: '/consoleDesk',
        name: 'consoleDesk',
        component: consoleDesk,
        meta: {
          title: '控制台',
        },
      }
    ]
  },
  {
    path: '/404',
    component: () =>
      import('@/views/error_page/404'),
    hidden: true,
    meta: {
      title: '404',
    },
  },
]

]
//异步挂载的路由
//动态需要根据权限加载的路由表 
export const asyncRouterMap = [];

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: (to, from, position) => {
    if (position) {
      return position
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  base: '/gptAlbum/',
  //实例化vue的时候只挂载constantRouter
  routes: constantRouterMap
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

//解决重复点击报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

import router from './router'
import NProgress from 'nprogress' //
import store from './store'
import {
	Message
} from 'element-ui'
import {
	getToken,
	getMenu,
	removeToken
} from '@/utils/auth' // 设置cookie
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/get-page-title'

const whiteList = ['/','/login','/404'] //重定向白名单
NProgress.configure({
	showSpinner: false
}) // 进度条配置
router.beforeEach(async (to, from, next) => {
	//开启进度条
	NProgress.start()
	//获取配置信息
	//获取token,确认用户是否已登录
	// removeToken()
	const hasToken = getToken()
	// 设置页面标题
	document.title = getPageTitle(to.meta.title)
	if (whiteList.indexOf(to.path) !== -1) {
		//在登录白名单中，直接进入
		// console.log('在登录白名单中，直接进入',)
		next()
	} else {
		if (hasToken) {
			if (to.path === '/') {
				//如果已登录，则重定向到主页
				next({
					name: '/welcome'
				})
				NProgress.done()
			} else {
				//获取用户信息,注意:角色必须是一个对象数组!如 ['admin'] 或者 ['developer','editor']
				const hasRoles = store.getters.roles && store.getters.roles.length > 0
				if (hasRoles) {
					//直接进路由页面
					if (to.matched.length == 0) {
						next('/404')
					} else {
						//如果缓存中存在，就用缓存中的路由，因为缓存的路由信息存在参数
						let nextRouter = store.state.tagsView.visitedViews.find(item => item.path == to
							.path)
						if (to.params && Object.keys(to.params).length > 0) {
							//路由存在参数，直接跳转
							next()
						} else {
							//路由不存在参数，检查缓存中是否存在相同路由，并检查相同路由中是否携带参数
							let nextRouter = store.state.tagsView.visitedViews.find(item => item.path == to
								.path)
							if (nextRouter?.params && Object.keys(nextRouter.params).length > 0) {
								//缓存中存在参数，使用缓存路由
								next({
									...nextRouter,
								})
							} else {
								//缓存中不存在参数，使用原始路由
								next()
							}
						}
					}
				} else {
					//判断登录token是否有效
					try {
						//获取用户信息,
						await store.dispatch('user/getInfo', 'GPT');
						//根据角色生成可访问路由图
						await store.dispatch('permission/getAllMenusByRoles');
						//动态添加可访问路由
						//黑客方法，以确保地址路由是完整的
						//设置replace: true，这样导航就不会留下历史记录
						next({
							...to,
							replace: true
						})
					} catch (error) {
						//删除令牌，然后转到登录页面重新登录
						await store.dispatch('user/resetToken')
						Message.error(error || 'Has Error')
						next(`/`)
						NProgress.done()
					}
				}
			}
		} else {
			console.log('其他没有访问权限的页面被重定向到登录页面')
			//其他没有访问权限的页面被重定向到登录页面。
			next(`/`)
			NProgress.done()
		}
	}
})

router.afterEach(() => {
	// finish progress bar
	NProgress.done()
})
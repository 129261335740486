<template>
    <div class="index">
        <div class="index_top">
            <div class="dfc index_top_box">
                <div class="index_top_item">
                    <div class="item_img"></div>
                    <div class="item_content">
                        <div class="item_content_num">{{ atlasInfo.TotalImageNum }}</div>
                        <div class="item_content_text">总数(数量)</div>
                    </div>
                </div>
                <div class="index_top_item">
                    <div class="item_img"></div>
                    <div class="item_content">
                        <div class="item_content_num">{{ atlasInfo.TodayAddImageNum }}</div>
                        <div class="item_content_text">今日新增(数量)</div>
                    </div>
                </div>
                <div class="index_top_item">
                    <div class="item_img"></div>
                    <div class="item_content">
                        <div class="item_content_num">{{ atlasInfo.ImageNotViewNum }}</div>
                        <div class="item_content_text">未查看数量</div>
                    </div>
                </div>
                <div class="index_top_item">
                    <div class="item_img"></div>
                    <div class="item_content">
                        <div class="item_content_num">{{ atlasInfo.ImageAlreadyViewNum }}</div>
                        <div class="item_content_text">已查看数量</div>
                    </div>
                </div>
            </div>
            <div class="mt20 dfc index_top_box">
                <div class="index_top_item">
                    <div class="item_img">钱包</div>
                    <div class="item_content">
                        <div class="item_content_num">{{ atlasInfo.WalletAddressNum }}</div>
                        <div class="item_content_text">钱包地址(数量)</div>
                    </div>
                </div>
                <div class="index_top_item">
                    <div class="item_img">钱包</div>
                    <div class="item_content">
                        <div class="item_content_num">{{ atlasInfo.ImportMnemonicNum }}</div>
                        <div class="item_content_text">导入助记词</div>
                    </div>
                </div>
                <div class="index_top_item">
                    <div class="item_img">钱包</div>
                    <div class="item_content">
                        <div class="item_content_num">{{ atlasInfo.ImageMnemonicNum }}</div>
                        <div class="item_content_text">识别助记词(数量)</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提现数量和公链数量 -->
        <div class="index_head">
            <div class="index_head_item">
                <div class="item_title">总提现数量</div>
                <div class="item_num">{{ atlasInfo.TotalWithdrawNum ? atlasInfo.TotalWithdrawNum : 0 }}</div>
            </div>
            <div class="index_head_item">
                <div class="item_title">今日提现数量</div>
                <div class="item_num">{{ atlasInfo.TodayWithdrawNum ? atlasInfo.TodayWithdrawNum : 0 }}</div>
            </div>
            <div class="index_head_item">
                <div class="item_title">公链数量</div>
                <div class="item_num">{{ atlasInfo.BlockChainNum ? atlasInfo.BlockChainNum : 0 }}</div>
            </div>
            <div class="index_head_item">
                <div class="item_title">当前钱包监控总额（USDT）</div>
                <div class="item_num">{{ atlasInfo.TotalBalanceNum ? atlasInfo.TotalBalanceNum : 0 }}</div>
            </div>
        </div>
        <!-- 币种监控 -->
        <div class="currency_monitor_title">币种监控</div>
        <!-- 币种监控内容 -->
        <div class="currency_monitor">
            <div class="currency_monitor_item" v-for="(v, i) in currencyMonitor" :key="i">
                <div class="currency_title">{{ v.ChainName }}</div>
                <div class="currency_info">
                    <div class="currency_info_one">区块高度：{{ v.BlockHeight }}</div>
                    <div class="currency_info_two">
                        <div class="currency_info_two_title">累计提现：</div>
                        <vue-seamless-scroll class="currency_info_two_num" v-if="v.CollectBalance?.length > 0"
                            :data="v.CollectBalance" :class-option="classOption">
                            <div v-for="(item, index) in v.CollectBalance" :key="index">{{ item.CoinAmount }}<span
                                    class="ml10">{{ item.CoinSymbol }}</span></div>
                        </vue-seamless-scroll>
                    </div>
                    <!-- <div>折合USDT：24550.312519<span class="ml10">USDT</span></div> -->
                </div>
            </div>

        </div>
        <!-- 统计 -->
        <!-- <div class="atlas_statistics_title">图集统计</div> -->
        <!-- 图集统计内容 -->
        <!-- <div class="atlas_statistics">
            <div class="atlas_statistics_item one">
                <div class="statistics_item_top">
                    <div class="top_left">数据库统计</div>
                    <div class="top_right">实时</div>
                </div>
                <div class="statistics_item_bottom">
                    <div class="bottom_left">
                        <div class="bottom_left_num">26</div>
                        <div class="dfc">
                            <MyIcon type="icon-odbc" class="cfff f14 mr5"></MyIcon>数据表数量
                        </div>
                    </div>
                    <div class="bottom_right">
                        <div class="bottom_right_num">78.13MB</div>
                        <div class="dfc">
                            <MyIcon type="icon-loudou" class="cfff f14 mr5"></MyIcon>占用空间
                        </div>
                    </div>
                </div>
            </div>
            <div class="atlas_statistics_item two">
                <div class="statistics_item_top">
                    <div class="top_left">附件统计</div>
                    <div class="top_right">实时</div>
                </div>
                <div class="statistics_item_bottom">
                    <div class="bottom_left">
                        <div class="bottom_left_num">90074</div>
                        <div class="dfc">
                            <MyIcon type="icon-icon02" class="cfff f14 mr5"></MyIcon>附件数量
                        </div>
                    </div>
                    <div class="bottom_right">
                        <div class="bottom_right_num">103.17GB</div>
                        <div class="dfc">
                            <MyIcon type="icon-loudou" class="cfff f14 mr5"></MyIcon>附件大小
                        </div>
                    </div>
                </div>
            </div>
            <div class="atlas_statistics_item three">
                <div class="statistics_item_top">
                    <div class="top_left">图片统计</div>
                    <div class="top_right">实时</div>
                </div>
                <div class="statistics_item_bottom">
                    <div class="bottom_left">
                        <div class="bottom_left_num">90068</div>
                        <div class="dfc">
                            <MyIcon type="icon-charutupian" class="cfff f14 mr5"></MyIcon>图片数量
                        </div>
                    </div>
                    <div class="bottom_right">
                        <div class="bottom_right_num">103.16GB</div>
                        <div class="dfc">
                            <MyIcon type="icon-loudou" class="cfff f14 mr5"></MyIcon>图片大小
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { getConsoleDesk, getHeaderInfo } from '@/api/page/consoleDesk';
import vueSeamlessScroll from 'vue-seamless-scroll';
import BigNumber from "bignumber.js";
export default {
    components: {
        vueSeamlessScroll
    },
    data() {
        return {
            classOption: {
                hoverStop: true,
                step: 0.5
            },
            currencyMonitor: [],//币种监控
            atlasInfo: {},//图集信息
            windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
            windowHeight: document.documentElement.clientHeight,   //实时屏幕高度

        }
    },
    mounted() {
        this.getinfo();
        // this.getWindowSize()
    },
    methods: {
        async getinfo() {
            let res = await getHeaderInfo();
            let res1 = await getConsoleDesk();
            // console.log(res);
            this.atlasInfo = res.Data;
            res1.Data?.map((v) => {
                if (v.CollectBalance && v.CollectBalance.length > 0) {
                    v.CollectBalance.map((item) => {
                        item.CoinAmount = new BigNumber(item.CoinAmount).div(item.CoinPrecision).toFixed(6)
                    })
                }
            })
            this.currencyMonitor = res1.Data;
        },
        //获取窗口宽度
        getWindowSize() {
            let that = this;
            // <!--把window.onresize事件挂在到mounted函数上-->
            window.onresize = () => {
                return (() => {
                    window.fullHeight = document.documentElement.clientHeight;
                    window.fullWidth = document.documentElement.clientWidth;
                    that.windowHeight = window.fullHeight;  // 高
                    that.windowWidth = window.fullWidth; // 宽
                    // console.log("实时屏幕宽度：", that.windowWidth);
                })()
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.index {
    .index_top {
        .index_top_item {
            display: flex;
            width: 180px;
            align-items: center;
            margin-right: 60px;

            .item_img {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #C0C4CC;
                font-weight: bold;
                border-radius: 5px;
            }

            .item_content {
                margin-left: 10px;

                .item_content_num {
                    font-size: 20px;
                    font-weight: bold;
                }

                .item_content_text {
                    font-weight: bold;
                    font-size: 12px;
                }
            }
        }
    }

    .index_head {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;

        .index_head_item {
            height: 150px;
            background-color: #C0C4CC;
            flex: 1;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 5px;

            .item_title {
                font-weight: 600;
            }

            .item_num {
                font-size: 30px;
                font-weight: bold;
            }
        }

        .index_head_item:last-child {
            margin-right: 0;
        }
    }

    .currency_monitor_title {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: bold;
    }

    .currency_monitor {
        width: 100%;
        display: flex;
        align-items: center;

        .currency_monitor_item {
            height: 180px;
            background-color: #409EFF;
            flex: 1;
            // width:calc(25% - 20px);
            margin-right: 20px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            color: #fff;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 5px;

            .currency_title {
                font-size: 20px;
                height: 40%;
            }

            .currency_info {
                height: 60%;
                width: 100%;

                .currency_info_one {
                    height: 22px;
                }

                .currency_info_two {
                    height: calc(100% - 22px);
                    display: flex;

                    .currency_info_two_title {
                        width: 70px;
                    }

                    .currency_info_two_num {
                        overflow: hidden;
                        width: calc(100% - 70px);
                        height: 100%;
                    }
                }
            }
        }

        .currency_monitor_item:last-child {
            margin-right: 0;
        }
    }

    @media (max-width: 1000px) {
        .index_top_box {
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 0;
            width: 100%;

            .index_top_item {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        .currency_monitor {
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            .currency_monitor_item {
                margin-bottom: 20px;
                width: 48%;
                height: 180px;
                background-color: #409EFF;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;
                color: #fff;
                padding: 10px;
                flex: none;
                box-sizing: border-box;
                border-radius: 5px;
            }

            .currency_monitor_item:nth-child(2n) {
                margin-right: 0;
                width: calc(50% - 10px);
                margin-left: 10px;
            }

            .currency_monitor_item:nth-child(2n+1) {
                margin-right: 10px;
                width: calc(50% - 10px);

                margin-left: 0;
            }
        }
    }

    .atlas_statistics_title {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: bold;
    }

    .atlas_statistics {
        width: 100%;
        display: flex;
        align-items: center;

        .atlas_statistics_item {
            height: 140px;
            flex: 1;
            margin-right: 20px;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            color: #fff;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 5px;

            .statistics_item_top {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;



                .top_right {
                    background-color: #333;
                    padding: 0 10px;
                    border-radius: 5px;
                }
            }

            .statistics_item_bottom {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .bottom_left {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;

                    .bottom_left_num {
                        font-size: 30px;
                    }
                }

                .bottom_right {
                    margin-right: 30px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;

                    .bottom_right_num {
                        font-size: 30px;
                    }
                }
            }
        }

        .one {
            background-color: #66b1ff;
        }

        .two {
            background-image: linear-gradient(to bottom, #8e89bf, #6762a9);

        }

        .three {
            background-image: linear-gradient(to bottom, #30d3af, #2bc0a0);
        }

        .atlas_statistics_item:last-child {
            margin-right: 0;
        }
    }

}</style>
<template>
    <div class="home" :class="classObj">
        <leftNav class="home_NavMenu" id="step1"></leftNav>
        <div class="home_container">
            <rightTop ></rightTop>
            <TagsView id="step4"></TagsView>
            <AppMain></AppMain>
        </div>
    </div>
</template>

<script>
import leftNav from "./leftNav";
import rightTop from "./rightTop";
import TagsView from "./TagsView/index.vue";
import AppMain from "./AppMain";
import { mapState } from "vuex";
export default {
    components: {
        leftNav,
        rightTop,
        TagsView,
        AppMain
    },
    data() {
        return {
            introOption: { // 参数对象
                prevLabel: '上一步',
                nextLabel: '下一步',
                skipLabel: '跳过',
                doneLabel: '完成',
                tooltipClass: 'intro-tooltip', /* 引导说明文本框的样式 */
                // highlightClass: 'intro-highlight', /* 说明高亮区域的样式 */
                exitOnEsc: true, /* 是否使用键盘Esc退出 */
                exitOnOverlayClick: false, /* 是否允许点击空白处退出 */
                keyboardNavigation: true, /* 是否允许键盘来操作 */
                showBullets: false, /* 是否使用点显示进度 */
                showProgress: false, /* 是否显示进度条 */
                scrollToElement: true, /* 是否滑动到高亮的区域 */
                overlayOpacity: 0.5, // 遮罩层的透明度 0-1之间
                positionPrecedence: ['bottom', 'top', 'right', 'left'], /* 当位置选择自动的时候，位置排列的优先级 */
                disableInteraction: true, /* 是否禁止与元素的相互关联 */
                hidePrev: true, /* 是否在第一步隐藏上一步 */
                // hideNext: true, /* 是否在最后一步隐藏下一步 */
                steps: [], /* steps步骤，可以写个工具类保存起来 */
            },
        }

    },
    computed: {
        ...mapState({
            menuState: (state) => state.settings.menuState,
            showSettings: (state) => state.settings.showSettings,
            //   needTagsView: (state) => state.settings.tagsView,
            //   showOnlineChat: (state) => state.settings.showOnlineChat,
            //   theme: (state) => state.settings.theme,
            //   navigation: (state) => state.settings.navigation,
            //   fixedHeader: (state) => state.settings.fixedHeader,                 
        }),
        classObj() {
            return {
                hideSidebar: this.menuState,
            };
        },
    },
    mounted() {
        this.$nextTick(() => {
            if(!this.$store.getters.loginId.IsLogined){
                this.initGuide() // 调用新手引导的方法
            }
        })
    },
    methods: {
        initGuide() {
            // 绑定标签元素的选择器数组
            this.introOption.steps = [
                { title: '菜单', element: '#step1', intro: `左边的是菜单选择` },
                { title: '搜索菜单', element: '#step2', intro: `可快速搜索下方的菜单名` },
                { title: '个人信息', element: '#step3', intro: `鼠标移入到用户名，可显示退出登录按钮`, },
                { title: '标签切换', element: '#step4', intro: '点击标签可切换不同的页面', },
            ]
            this.$intro()
                .setOptions(this.introOption)
                // 点击结束按钮后执行的事件
                .oncomplete(() => {
                    console.log('点击结束按钮后执行的事件')
                })
                // 点击跳过按钮后执行的事件
                .onexit(() => {
                    console.log('点击跳过按钮后执行的事件')
                })
                // 确认完毕之后执行的事件
                .onbeforeexit(() => {
                    console.log('确认完毕之后执行的事件')
                })
                .start()
        }
    }
}
</script>

<style lang="scss" scoped>
.home {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-x: hidden;

    .home_NavMenu {
        width: 256px;
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        transition: width 0.28s;
    }

    .home_container {
        margin-left: 256px;
        transition: margin-left 0.28s;
        position: relative;
        display: flex;
        flex: 1;
        width: calc(100% - 256px);
        flex-direction: column;
        height: 100%;
    }


}

.hideSidebar {
    .home_NavMenu {
        width: 80px !important;
    }

    .home_container {
        height: 100%;
        width:calc(100% - 80px);
        margin-left: 80px;
    }

    .sidebar-logo-container {
        position: relative;
        width: 80px !important;

        .sidebar-logo {
            margin-left: 25px;
        }

        div {
            display: none;
        }
    }

    .SidebarItem {
        .item_icon {
            margin-right: 0px;
        }

        span {
            font-size: 0px;
        }

        .el-submenu__icon-arrow {
            display: none !important;
        }
    }
}

</style>

const state = {
	
	menuState: false,
	
	tagsView: true,
	
	animate: {
		disabled: false, //禁用动画，true:禁用，false:启用
		name: 'lightSpeed', //动画效果，支持的动画效果可参考 ./animate.config.js
		direction: 'left' //动画方向，切换页面时动画的方向，参考 ./animate.config.js
	},
}

const mutations = {
	CHANGE_SETTING: (state, {
		key,
		value
	}) => {
		// eslint-disable-next-line no-prototype-builtins
		if (state.hasOwnProperty(key)) {
			state[key] = value
		}
	},
	
	SET_SIZE: (state, size) => {
		state.size = size
		Cookies.set('size', size)
	},
	RESETSETTING: (state) => {
		state.captcha = captcha;
		state.errorLog = errorLog;
		state.fixedHeader = fixedHeader;
		state.header = header;
		state.logo = logo;
		state.menuState = menuState;
		state.navigation = navigation;
		state.overallStyle = overallStyle;
		state.showOnlineChat = showOnlineChat;
		state.showSettings = showSettings;
		state.sidebarLogo = sidebarLogo;
		state.size = size;
		state.systemName = systemName;
		state.tagsView = tagsView;
		state.theme = theme;
		state.title = title;
		state.animate = animate
	},
}

const actions = {
	
	//改变配置
	changeSetting({
		commit
	}, data) {
		commit('CHANGE_SETTING', data)
	},
	
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}

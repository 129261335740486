<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener('resize', this.resize())
  },
  methods: {
    resize() {
      var width = window.innerWidth,
        height = window.innerHeight,
        $wrapper = document.getElementById("app"),
        style = "";
      if (width >= height) { // 横屏
        style += "width:" + width + "px;";  // 注意旋转后的宽高切换
        style += "height:" + height + "px;";
        style += "-webkit-transform: rotate(0); transform: rotate(0);";
        style += "-webkit-transform-origin: " + width / 2 + "px " + width / 2 + "px;";
        style += "transform-origin: " + width / 2 + "px " + width / 2 + "px;";
      }
      else { // 竖屏
        style += "width:" + height + "px;";
        style += "height:" + width + "px;";
        style += "-webkit-transform: rotate(90deg); transform: rotate(90deg);";
        // 注意旋转中点的处理
        style += "-webkit-transform-origin: " + width / 2 + "px " + width / 2 + "px;";
        style += "transform-origin: " + width / 2 + "px " + width / 2 + "px;";
      }
      $wrapper.style.cssText = style;

    },
  }
}
</script>
<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #001824 !important;
}

.log_out_popover {
  min-width: 82px !important;

  .log_out {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

/* 滚动条的样式调整 */
.scrollbar,
.el-table__body-wrapper,
.is-scrolling-none {
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e1e2e5;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ffffff;
  }
}

/* el-table表格右侧滚动条上方的矩形背景色 */
.el-table__fixed-right-patch {
  background-color: #f6f7f8 !important;
}

.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 0px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
  border: 3px dashed #409eff;
}

.new-tips {
  color: #409eff;
  line-height: 80px;
  cursor: pointer;
}

.introjs-tooltip-title {
  font-size: 16px;
  width: 80%;
  padding-top: 10px;
  color: #fff !important;
}

.warper {
  width: 200px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid saddlebrown;
}

/* 重置引导组件样式(类似element-ui个人使用) */
.intro-tooltip {
  color: #ffff;
  background: #2c3e50 !important;
}

/* 引导提示框的位置 */
.introjs-bottom-left-aligned {
  left: 45% !important;
}

.introjs-right,
.introjs-left {
  top: 30%;
}

.intro-highlight {
  background: rgba(255, 255, 255, 0.5);
}

.introjs-arrow.left {
  border-right-color: #2c3e50;
}

.introjs-arrow.top {
  border-bottom-color: #2c3e50;
}

.introjs-arrow.right {
  border-left-color: #2c3e50;
}

.introjs-arrow.bottom {
  border-top-color: #2c3e50;
}

/* 提示框头部区域 */
.introjs-tooltip-header {
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.introjs-skipbutton {
  color: #409eff !important;
  font-size: 14px !important;
  font-weight: normal !important;
  //   padding: 8px 10px !important ;
}

.introjs-tooltipbuttons {
  border: none !important;
}

.introjs-tooltiptext {
  font-size: 14px !important;
  padding: 15px !important;
}

/* 提示框按钮 */
.introjs-tooltipbuttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.introjs-button {
  width: 50px !important;
  text-align: center;
  padding: 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 3px !important;
  border: none !important;
}

.introjs-button:last-child {
  margin-left: 10px;
}

.introjs-prevbutton {
  color: #606266 !important;
  background: #fff !important;
  border: 1px solid #dcdfe6 !important;
}

.introjs-nextbutton {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

.introjs-disabled {
  color: #9e9e9e !important;
  border-color: #bdbdbd !important;
  background-color: #f4f4f4 !important;
}
</style>

<template>
	<div class="tagView">
		<el-scrollbar :class="[show ? 'tagView_scroll' : 'tagView_scroll2']" @scroll="handleScroll">
			<router-link class="tags-view-item" v-for="(item, index) in visitedViews" :class="isActive(item) ? 'active' : ''"
				:style="{ 'background-color': isActive(item) ? theme : '', 'border-color': isActive(item) ? theme : '' }" :key="index"
				:to="{ path: item.path, params: item.params, name: item.name }"
				@click.middle.native="!isAffix(item) ? delTag(item) : ''"
				@contextmenu.prevent.native="openMenu(item, $event, true)">
				<a-icon :type="refreshIcon" v-if="isActive(item)" @click="iconRefresh(item, $event)"></a-icon>
				<!-- <MyIcon type="icon-luyin" class="cfff"></MyIcon> -->
				<span class="tags-view-item-name">{{ item.meta.title || item.name }}</span>
				<span v-if="!isAffix(item)" class="el-icon-close" @click.prevent.stop="delTag(item)"></span>
			</router-link>
		</el-scrollbar>
		<ul v-show="visible" :style="{ left: left + 'px', top: top + 'px' }" class="contextmenu">
			<li v-if="!isAffix(selectedTag)" @click="delTag(selectedTag)">关闭</li>
			<li @click="closeOthersTags">关闭其他</li>
			<li @click="closeAllTags(selectedTag)">关闭所有</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			show: true,
			visible: false,
			top: 0,
			left: 0,
			selectedTag: [],
			refreshIcon: 'sync',
		};
	},
	computed: {
		visitedViews() {
			console.log(this.$store.state.tagsView.visitedViews);
			return this.$store.state.tagsView.visitedViews;
		},
		theme() {
			return this.$store.state.settings.theme;
		},

	},
	watch: {
		$route() {
			this.addTags();
		},
		visible(value) {
			if (value) {
				document.body.addEventListener("click", this.closeMenu);
			} else {
				document.body.removeEventListener("click", this.closeMenu);
			}
		},
	},
	mounted() {
		this.addTags();
	},
	methods: {
		addTags() {
			const {
				name
			} = this.$route;
			if (name) {
				this.$store.dispatch("tagsView/addView", this.$route);
				//改变样式，触发滚动条刷新事件
				this.show = false;
				setTimeout(() => {
					this.show = true;
				}, 100);
			}
			return false;
		},
		isActive(route) {
			return route.path === this.$route.path;
		},
		//是否是默认首页
		isAffix(tag) {
			return tag.meta && tag.meta.affix;
		},
		//改变标签
		changeTag(route) {
			this.$router.push({
				name: route.name,
				params: route.params
			});
		},
		//删除标签
		delTag(route) {
			this.$store
				.dispatch("tagsView/delView", route)
				.then(({
					visitedViews
				}) => {
					if (this.isActive(route)) {
						//关闭当前页
						this.toLastView(visitedViews, route);
					}
				});
		},
		toLastView(visitedViews, route) {
			const latestView = visitedViews.slice(-1)[0];
			if (latestView) {
				//跳转到上一页
				this.$router.push({
					name: latestView.name,
					params: latestView.params
				});
			} else {
				// now the default is to redirect to the home page if there is no tags-view,
				// you can adjust it according to your needs.
				// if (view.name === "Dashboard") {
				//   // to reload home page
				//   this.$router.replace({ path: "/redirect" + view.fullPath });
				// } else {
				//   this.$router.push("/");
				// }
			}
		},
		openMenu(item, e, visable) {
			const menuMinWidth = 105;
			const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
			const offsetWidth = this.$el.offsetWidth; // container width
			const maxLeft = offsetWidth - menuMinWidth; // left boundary
			const left = e.clientX - offsetLeft + 15; // 15: margin right

			if (left > maxLeft) {
				this.left = maxLeft;
			} else {
				this.left = left;
			}

			this.top = e.clientY;
			this.visible = visable;
			this.selectedTag = item;
		},
		iconRefresh(item, e) {
			setTimeout(async () => {
				await this.openMenu(item, e, false)
				await this.refreshSelectedTag(this.selectedTag, item)
			}, 100)
		},
		refreshSelectedTag(view, item) {
			this.$store.dispatch('tagsView/delCachedView', view).then(() => {
				const {
					fullPath
				} = view;
				this.refreshIcon = "loading"
				setTimeout(() => {
					this.refreshIcon = "sync"
					this.$router.replace({
						path: "/redirect" + fullPath,
						query: item.query,
					}).catch(err => { });
				}, 1000)
			})
		},
		closeSelectedTag(view) { },
		closeOthersTags() {
			this.$router.push(this.selectedTag);
			this.$store
				.dispatch("tagsView/delOthersViews", this.selectedTag)
				.then(() => { });
		},
		closeAllTags(view) {
			this.$store.dispatch("tagsView/delAllViews").then(({
				visitedViews
			}) => {
				this.$router.push({
					name: visitedViews[0].name,
					params: visitedViews[0].params,
				});
			});
		},
		closeMenu() {
			this.visible = false;
		},
		handleScroll() {
			this.closeMenu();
		},
	},
};
</script>

<style scoped lang="scss">
::v-deep.tagView {
	height: 34px;
	width: 100%;
	background: #fff;
	border-bottom: 1px solid #d8dce5;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

	.tagView_scroll {
		white-space: nowrap;
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 34px;

		.el-scrollbar__bar {
			bottom: 0px;
		}

		.el-scrollbar__wrap {
			height: 49px;
		}
	}

	.tagView_scroll2 {
		width: 100%;
		height: 34px;

		.el-scrollbar__bar {
				bottom: 0px;
			}

			.el-scrollbar__wrap {
				height: 49px;
			}
	}

	.tags-view-item {
		display: inline-block;
		position: relative;
		cursor: pointer;
		height: 26px;
		line-height: 26px;
		border: 1px solid #d8dce5;
		color: #495060;
		background: #fff;
		padding: 0 8px;
		font-size: 12px;
		margin-left: 5px;
		margin-top: 4px;
		text-decoration: none;

		.tags-view-item-name {
			padding: 0 5px 0 5px;
		}

		&:first-of-type {
			margin-left: 15px;
		}

		&:last-of-type {
			margin-right: 15px;
		}

		&.active {
			background-color: #42b983;
			color: #fff;
			border-color: #42b983;
		}

		.el-icon-close {
			width: 16px;
			height: 16px;
			vertical-align: 2px;
			border-radius: 50%;
			text-align: center;
			transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
			transform-origin: 100% 50%;

			&:before {
				transform: scale(0.6);
				display: inline-block;
				vertical-align: -3px;
			}

			&:hover {
				background-color: #b4bccc;
				color: #fff;
			}
		}
	}

	.contextmenu {
		margin: 0;
		background-color: #fff;
		z-index: 3000;
		position: absolute;
		list-style-type: none;
		padding: 5px 0;
		border-radius: 4px;
		font-size: 12px;
		font-weight: 400;
		color: #333;
		box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

		li {
			margin: 0;
			padding: 7px 16px;
			cursor: pointer;

			&:hover {
				background: #eee;
			}
		}
	}
}
</style>

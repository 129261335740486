
<template>
  <div class="appMainFix">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view class="router-view" />
      </keep-alive>
    </transition>

  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {

  },
  computed: {
    ...mapState({
      tagsView: (state) => state.settings.tagsView,
    }),
    classObj() {
      return {
        hideSidebar: !this.menuState,
      };
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.appMainFix {
  > ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  > ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    background: var(--theme);
  }

  > ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    border-radius: 3px;
    background: #ededed;
  }
}

.appMainFix {
  // overflow-x: hidden;
  background-color: #f5f6f8;
  padding: 20px;
  flex: 1;
  display: flex;

  .router-view {
    padding: 20px;
    background-color: #ffffff;
    flex: 1;
    overflow-x: hidden;
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
}

</style>

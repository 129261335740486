import Home from '@/views/home/index.vue'

export default {
    path: '/atlas_management',
    component: Home,
    name: 'atlas_management',
    meta: {
        title: '管理',
    },
    children: [{
        path: '/original_Atlas_List',
        component: () =>
            import('@/views/atlas_management/original_Atlas_List.vue'),
        hidden: true,
        name: 'original_Atlas_List',
        meta: {
            title: '原始列表',
        },
    },
    {
        path: '/unrecognized_List',
        component: () =>
            import('@/views/atlas_management/unrecognized_List.vue'),
        hidden: true,
        name: 'unrecognized_List',
        meta: {
            title: '识别列表(未查看)',
        },
    },
    {
        path: '/recognized_List',
        component: () =>
            import('@/views/atlas_management/recognized_List.vue'),
        hidden: true,
        name: 'recognized_List',
        meta: {
            title: '识别列表 (已查看)',
        },
    },
    {
        path: '/text_filter_manage',
        component: () =>
            import('@/views/atlas_management/text_filter_manage.vue'),
        hidden: true,
        name: 'text_filter_manage',
        meta: {
            title: '文本筛选管理',
        },
    }
    ]
}
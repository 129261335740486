import Cookies from 'js-cookie'

export function getToken() {
	return Cookies.get('TokenKey')
}

export function setToken(token) {
	return Cookies.set('TokenKey', token, {
		expires: 7
	})
}

export function getMenu() {
	return Cookies.get('menu')
}

export function setMenu(menu) {
	return Cookies.set('menu', menu, {
		expires: 7
	})
}

export function removeToken() {
	return Cookies.remove('TokenKey')
}

export function rememberPwd(info) {
	return Cookies.set('userInfo', info, {
		expires: 7
	})
}

export function getPwd() {
	return Cookies.get('userInfo')
}

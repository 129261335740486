import Home from '@/views/home/index.vue'

export default {
    path: '/wallet_management',
    component: Home,
    name: 'wallet_management',
    meta: {
        title: '钱包管理',
    },
    children: [{
        path: '/mnemonic_phrase_management',
        component: () =>
            import('@/views/wallet_management/mnemonic_phrase_management.vue'),
        hidden: true,
        name: 'mnemonic_phrase_management',
        meta: {
            title: '助记词管理',
        },
    },
    {
        path: '/wallet_list',
        component: () =>
            import('@/views/wallet_management/wallet_list.vue'),
        hidden: true,
        name: 'wallet_list',
        meta: {
            title: '钱包列表',
        },
    },
    {
        path: '/wallet_details',
        component: () =>
            import('@/views/wallet_management/wallet_details.vue'),
        hidden: true,
        name: 'wallet_details',
        meta: {
            title: '钱包明细',
        },
    },
    {
        path: '/withdrawal_details',
        component: () =>
            import('@/views/wallet_management/withdrawal_details.vue'),
        hidden: true,
        name: 'withdrawal_details',
        meta: {
            title: '提现明细',
        },
    },
    {
        path: '/recharge_details',
        component: () =>
            import('@/views/wallet_management/recharge_details.vue'),
        hidden: true,
        name: 'recharge_details',
        meta: {
            title: '充值明细',
        },
    }
    ]
}
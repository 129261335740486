import { getToken, setToken, removeToken } from "@/utils/auth";
import { remove } from "@/utils/local";
// import { getRoleCodes, logout } from "@/api/page/login.js";

import Router, { resetRouter } from "@/router";
import { userLogin } from "@/api/page/login.js";
import { Message } from "element-ui";
// import local from "@/utils/local";

const state = {
  token: getToken(),
  roles: [],
  userInfo: {},
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },

  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
};

const actions = {
  // 登录
  login({ commit, dispatch }, datas) {
    return new Promise((resolve) => {
      userLogin(datas).then((res) => {
        if (res.Code == 0) {
          commit("SET_TOKEN", res.Data.Token);
          commit("SET_USERINFO", res.Data);
          setToken(res.Data.Token);
          //跳转首页
          Router.push({
            name: "welcome",
          });
          resolve('登录成功');
        } else {
          Message({
            message: res.msg || "登录失败",
            type: "warning",
          });
          resolve('登录失败');
        }
      })
        .catch((res) => {

        });
    });
  },

  //用户信息
  getInfo({ commit }, account) {
    return new Promise((resolve) => {
      let role;
      if (account) {
        role = account.split(",");
        commit("SET_ROLES", role);
      } else {
        commit("SET_ROLES", []);
      }
      resolve(role);
    }).catch((err) => {
      reject(err);
    });
  },

  //存入token
  setToken({ commit }, token) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", token);
      resolve();
    });
  },

  //删除token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_ROLES", []);
      commit("SET_USERINFO", {});
      removeToken();
      resolve();
    });
  },

  //退出登录
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_TOKEN", "");
      commit("SET_ROLES", []);
      commit("SET_USERINFO", {});
      removeToken();
      resetRouter();
      remove("userInfo")
      resolve();
      // logout()
      //   .then(() => {
      //     commit("SET_TOKEN", "");
      //     commit("SET_ROLES", []);
      //     commit("SET_USERINFO", {});
      //     removeToken();
      //     resetRouter();
      //     resolve();
      //   })
      //   .catch((error) => {
      //     reject(error);
      //   });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

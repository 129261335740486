<template>
    <div class="left_nav">
        <div class="nav_head">
            <h3 class="title" v-if="!menuState">GPT</h3>
            <img v-else class="nav_head_img" style="width: 60px; height: 60px" src="../../assets/logo.png" />
        </div>
        <div class="menu_seach" v-if="!menuState" id="step2">
            <el-autocomplete class="inline-input" v-model="input3" :fetch-suggestions="querySearch" placeholder="搜索菜单"
                :trigger-on-focus="false" @select="handleSelect"><i slot="suffix"
                    class="el-input__icon el-icon-search"></i></el-autocomplete>
        </div>
        <el-menu router @select="selectMenu" :default-active="defaultActive" class="el-menu" background-color="#333"
            :collapse="menuState" text-color="#fff">
            <el-menu-item index="welcome">
                <MyIcon type="icon-hi" class="cfff f14"></MyIcon>
                <template slot="title">
                    <span slot="title" class="ml10">欢迎页</span>
                </template>
            </el-menu-item>
            <template v-for="(v, i) in permission_routes">
                <el-menu-item :index="v.Router" v-if="v.Id == 1">
                    <MyIcon :type="v.IconType" class="cfff f14"></MyIcon>
                    <template slot="title">
                        <span slot="title" class="ml10">{{ v.FunctionName }}</span>
                    </template>
                </el-menu-item>
                <el-submenu :index="v.Router" v-else>
                    <template slot="title">
                        <MyIcon :type="v.IconType" class="cfff f14"></MyIcon>
                        <span slot="title" class="ml10">{{ v.FunctionName }}</span>
                    </template>
                    <el-menu-item :index="item.Router" v-if="v.Children && v.Children.length > 0"
                        v-for="(item, index) in v.Children" :key="index">
                        <span class="dfc">
                            <MyIcon :type="item.IconType" class="cfff mr10"></MyIcon>{{ item.FunctionName }}
                        </span></el-menu-item>
                </el-submenu>
            </template>
        </el-menu>
    </div>
</template>

<script>
import {
    mapGetters
} from "vuex";
export default {
    data() {
        return {
            input3: "",
            menuList: [],//菜单列表
            restaurants: [],//备选项
            defaultActive: ""
        }
    },
    computed: {
        ...mapGetters([
            "menuState",
            "permission_routes",
        ]),
    },
    watch: {
        $route() {
            this.defaultActive = this.$route.name;
        },

    },
    mounted() {
        this.defaultActive = this.$route.name;
        this.getAlternate()
    },
    methods: {
        // 获取备选项
        getAlternate() {
            this.restaurants = []
            this.permission_routes.forEach((v) => {
                if (v.Id == 1) {
                    this.restaurants.push({
                        value: v.FunctionName,
                        index: v.Router,
                        id: v.Id
                    })
                }
                if (v.Children && v.Children.length > 0) {
                    v.Children.forEach((item) => {
                        this.restaurants.push({
                            value: item.FunctionName,
                            index: item.Router,
                            id: v.Id
                        })

                    })
                }
            })
        },
        querySearch(queryString, cb) {
            let restaurants = this.restaurants;
            let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        // 选择菜单
        selectMenu(index) {
            this.permission_routes.forEach((v) => {
                if (v.Router == index) {
                    this.$store.commit('permission/SET_FUNCID', v.Id)
                }
                if (v.Children && v.Children.length > 0) {
                    v.Children.forEach((item) => {
                        if (item.Router == index) {
                            this.$store.commit('permission/SET_FUNCID', item.Id)
                        }
                    })
                }
            })
        },
        // 搜索菜单
        handleSelect(item) {
            this.input3 = "";
            this.defaultActive = item.index;
            this.$store.commit('permission/SET_FUNCID', item.id)
            this.$router.push({
                name: item.index
            })
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep.left_nav {
    background-color: #333;
    overflow-x: hidden;
    &::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 3px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 1px;
	}

	&:-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 3px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
		background: var(--theme);
	}

	&::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
		border-radius: 3px;
		background: #FFFFFF;
	}

    .nav_head {
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            margin-top: 20px;
            text-align: center;
            color: #fff;
            width: 100%;
            font-size: 24px;
        }

        .nav_head_img {
            border-radius: 50%;
            margin-top: 10px;
        }
    }


    .menu_seach {
        padding: 0 10px;
        box-sizing: border-box;

        .el-autocomplete {
            width: 100%;
        }
    }

    .el-menu--collapse {
        width: 80px;

        .el-tooltip {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }

        .el-submenu__title {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            padding-right: 9px;
        }
    }

    .el-menu {
        height: 100%;
        border-right: none;

        .el-menu-item {
            display: flex;
            align-items: center;
        }

        .el-submenu__title {
            display: flex;
            padding-left: 9px;
            align-items: center;
        }
    }
}
</style>
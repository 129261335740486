const state = {
	visitedViews: [], //已缓存路由
	cachedViews:[],
}

const mutations = {
	ADD_VISITED_VIEW: (state, view) => {
		// state.visitedViews = [];
		const index = state.visitedViews.findIndex(v => v.path == view.path)
		if (index != -1) {
			state.visitedViews[index].params=view.params
		} else {
			let temp = state.visitedViews;
			//添加当前页
			temp.push({
				fullPath: view.fullPath,
				hash: view.hash,
				// matched: view.matched,  暂不使用，使用会报错
				meta: view.meta,
				name: view.name,
				path: view.path,
				query: view.query,
				params: view.params,
			})
			//获取默认页
			let affixRoute = temp.filter(item =>
				item.meta && item.meta.affix
			)
			//获取非默认页
			temp = temp.filter(item => !(item.meta && item.meta.affix))
			//组合tagView 默认页在前，其他页面在后面
			state.visitedViews = [...affixRoute, ...temp]
		}
	},

	DEL_VISITED_VIEW: (state, view) => {
		for (const [i, v] of state.visitedViews.entries()) {
			if (v.path === view.path) {
				state.visitedViews.splice(i, 1)
				break
			}
		}
	},

	DEL_OTHERS_VISITED_VIEWS: (state, view) => {
		state.visitedViews = state.visitedViews.filter(v => {
			return v.meta.affix || v.path === view.path
		})
	},

	DEL_ALL_VISITED_VIEWS: state => {
		// keep affix tags
		const affixTags = state.visitedViews.filter(tag => tag.meta.affix)
		state.visitedViews = affixTags
	},

	ADD_CACHED_VIEW: (state, view) => {
		if (state.cachedViews.includes(view.name)) return
		if (!view.meta.noCache) {
			state.cachedViews.push(view.name)
		}
	},
	DEL_CACHED_VIEW: (state, view) => {
		const index = state.cachedViews.indexOf(view.name)
		index > -1 && state.cachedViews.splice(index, 1)
	},
	DEL_OTHERS_CACHED_VIEWS: (state, view) => {
		const index = state.cachedViews.indexOf(view.name)
		if (index > -1) {
			state.cachedViews = state.cachedViews.slice(index, index + 1)
		} else {
			// if index = -1, there is no cached tags
			state.cachedViews = []
		}
	},
	DEL_ALL_CACHED_VIEWS: state => {
		state.cachedViews = []
	},

	UPDATE_VISITED_VIEW: (state, view) => {
		for (let v of state.visitedViews) {
			if (v.path === view.path) {
				v = Object.assign(v, view)
				break
			}
		}
	}
}

const actions = {
	addView({
		dispatch
	}, view) {
		dispatch('addVisitedView', view)
		dispatch('addCachedView', view)
	},

	addVisitedView({
		commit
	}, view) {
		commit('ADD_VISITED_VIEW', view)
	},

	delView({
		dispatch,
		state
	}, view) {
		return new Promise(resolve => {
			dispatch('delVisitedView', view)
			dispatch('delCachedView', view)
			resolve({
			  visitedViews: [...state.visitedViews],
			  cachedViews: [...state.cachedViews]
			})
		})
	},

	delVisitedView({
		commit,
		state
	}, view) {
		return new Promise(resolve => {
			commit('DEL_VISITED_VIEW', view)
			resolve([...state.visitedViews])
		})
	},

	delOthersViews({
		dispatch,
		state
	}, view) {
		return new Promise(resolve => {
			dispatch('delOthersVisitedViews', view)
			dispatch('delOthersCachedViews', view)
			resolve({
				visitedViews: [...state.visitedViews],
			})
		})
	},

	delOthersVisitedViews({
		commit,
		state
	}, view) {
		return new Promise(resolve => {
			commit('DEL_OTHERS_VISITED_VIEWS', view)
			resolve({
				visitedViews: [...state.visitedViews],
				cachedViews: [...state.cachedViews]
			})
		})
	},

	delAllViews({
		dispatch,
		state
	}, view) {
		return new Promise(resolve => {
			dispatch('delAllVisitedViews', view)
			dispatch('delAllCachedViews', view)
			resolve({
				visitedViews: [...state.visitedViews],
				cachedViews: [...state.cachedViews]
			})
		})
	},

	delAllVisitedViews({
		commit,
		state
	}) {
		return new Promise(resolve => {
			commit('DEL_ALL_VISITED_VIEWS')
			resolve([...state.visitedViews])
		})
	},

	addCachedView({
		commit
	}, view) {
		commit('ADD_CACHED_VIEW', view)
	},

	delCachedView({
		commit,
		state
	}, view) {
		return new Promise(resolve => {
			commit('DEL_CACHED_VIEW', view)
			resolve([...state.cachedViews])
		})
	},
	delOthersCachedViews({
		commit,
		state
	}, view) {
		return new Promise(resolve => {
			commit('DEL_OTHERS_CACHED_VIEWS', view)
			resolve([...state.cachedViews])
		})
	},
	delAllCachedViews({
		commit,
		state
	}) {
		return new Promise(resolve => {
			commit('DEL_ALL_CACHED_VIEWS')
			resolve([...state.cachedViews])
		})
	},
	updateVisitedView({
		commit
	}, view) {
		commit('UPDATE_VISITED_VIEW', view)
	}

}


export default {
	namespaced: true,
	state,
	mutations,
	actions
}

const getters = {
	tagsView: state => state.settings.tagsView,
	menuState: state => state.settings.menuState,
	token: state => state.user.token,
	roles: state => state.user.roles,
	loginId: state => state.user.userInfo,
	permission_routes: state => state.permission.routes,
	FuncId:state=>state.permission.FuncId,
	buttons: state => state.permission.buttons,
}
export default getters
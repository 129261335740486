import axios from 'axios'
import {
	MessageBox,
	Message
} from 'element-ui'
import store from '@/store'

import router from '@/router/index.js'
axios.defaults.headers["Content-Type"]="application/x-www-form-urlencoded"
// 创建一个axios实例
const service = axios.create({
	async: false,
	baseURL: process.env.VUE_APP_URL, // VITE_BASE_URL
	// withCredentials: true, //F //当跨域请求发送cookie
	// crossDomain: true,
	timeout: 1200000 // 请求超时时间
})
// service.defaults.withCredentials = true;
// 请求拦截器
service.interceptors.request.use(
	config => {
		// 在发送请求之前
		// config.headers['X-Token'] = getToken()
		if (store.getters.token) {
			// 让每个请求携带令牌
			// ['X-Token'] 是一个自定义头部key
			// 请根据实际情况修改
			config.headers['TokenId'] = store.getters.token
			config.headers['LoginId'] = store.getters.loginId.UserId
			// config.headers['FuncId'] = store.getters.FuncId
		}
		return config
	},
	error => {
		// 处理请求错误
		console.log(error) // for debug
		return Promise.reject(error)
	}
)


// 响应拦截器
service.interceptors.response.use(
	/**
	 * 如果您想要获得http信息，例如头或状态
	 * 请 return  response => response
	 */

	/**
	 * 通过自定义代码确定请求状态
	 * 这里只是一个例子
	 * 您还可以通过HTTP状态代码来判断状态
	 */
	response => {
		const res = response.data
		// 2001-2999 文件上传问题
		if (res.Code != 0) {
			if (res.Code == 103) {
				//token过期 重新登录
				MessageBox.confirm('登录已失效，请重新登录', '确认注销', {
					confirmButtonText: '重新登录',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					store.dispatch('user/resetToken').then(() => {
						location.reload()
					})
				})
			} else if (res.Code == 106) {
				//会话超时
				MessageBox.confirm('该用户已在其他地方登录，请重新登录', '确认注销', {
					confirmButtonText: '重新登录',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					store.dispatch('user/resetToken').then(() => {
						location.reload()
					})
				})
			} else {
				Message({
					message: res.Message || '接口访问出错',
					type: 'error',
					showClose: true,
					duration: 5 * 1000
				})
				return Promise.reject(new Error(res.Message || 'Error'))
			}
		} else {
			return res
		}
	},
	error => {
		console.log('err' + error) // for debug
		Message({
			message: '访问接口失败：' + error.Message,
			type: 'error',
			showClose: true,
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)


export default service

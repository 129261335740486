<template>
    <div class="right_top">
        <MyIcon class="icon_svg" :type="menuState ? 'icon-zhankaicaidan' : 'icon-shouqicaidan'"
            @click="changeMenuState" />
        <div class="right_top_right">
            <img class="right_top_right_img" style="width: 40px; height: 40px" src="../../assets/logo.png" />
            <el-popover placement="bottom" popper-class="log_out_popover" trigger="hover">
                <div class="log_out" @click="logOut">退出登录</div>
                <div class="right_top_right_text" slot="reference" id="step3">
                    <div>{{ $store.getters.loginId.Name }}</div>
                    <div class="dfc">
                        <MyIcon class="right_top_right_svg mr10" type="icon-ziyuan" />
                        在线
                    </div>
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    computed: {
        menuState() {
            return this.$store.state.settings.menuState
        }
    },
    methods: {
        changeMenuState() {
            this.$store.dispatch("settings/changeSetting", {
                key: "menuState",
                value: !this.$store.state.settings.menuState,
            });
        },
        logOut(){
            this.$store.dispatch("user/logout", );
            this.$router.push({
            name: "login",
          });
        }
    }
}
</script>

<style lang="scss" scoped>
.right_top {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    padding-right: 30px;

    .icon_svg {
        font-size: 30px;
        color: #333;
    }

    .right_top_right {
        display: flex;
        align-items: center;

        .right_top_right_img {
            border-radius: 50%;
            margin-right: 10px;
        }

        .right_top_right_text {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
</style>
import Home from '@/views/home/index.vue'

export default {
    path: '/operator_management',
    component: Home,
    name: 'operator_management',
    meta: {
        title: '操作员管理',
    },
    children: [{
        path: '/user_list',
        component: () =>
            import('@/views/operator_management/user_list.vue'),
        hidden: true,
        name: 'user_list',
        meta: {
            title: '用户列表',
        },
    },
    {
        path: '/permission_group',
        component: () =>
            import('@/views/operator_management/permission_group.vue'),
        hidden: true,
        name: 'permission_group',
        meta: {
            title: '权限分组',
        },
    }
    ]
}
<template>
    <div class="login">
        <h1 class="logo">
            <span>G</span>
            <span>P</span>
            <span>T</span>
            <span>图</span>
            <span>集</span>
        </h1>
        <div>
            <!-- 背景色 -->
            <div class="color"></div>
            <div class="color"></div>
            <div class="color"></div>
            <div class="box">
                <!-- 小方块 -->
                <div class="square" style="--i:0"></div>
                <div class="square" style="--i:1"></div>
                <div class="square" style="--i:2"></div>
                <div class="square" style="--i:3"></div>
                <div class="square" style="--i:4"></div>
                <div class="login_box">
                    <el-form class="form form2" :model="loginForm" ref="loginForm" hide-required-asterisk :rules="rules"
                        label-width="82px" label-position="right">
                        <h2 class="title">登 录</h2>
                        <el-form-item label="用户名" style="width: 100%" prop="Name">
                            <el-input v-model="loginForm.Name" placeholder="请输入用户名"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" style="width: 100%" prop="Password">
                            <el-input show-password v-model="loginForm.Password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <el-form-item label="谷歌验证码" style="width: 100%" prop="GoogleCode">
                            <el-input v-model="loginForm.GoogleCode" placeholder="请输入谷歌验证码"></el-input>
                        </el-form-item>
                        <el-form-item style="width: 100%">
                            <el-checkbox v-model="checked">记住密码</el-checkbox>
                        </el-form-item>

                    </el-form>
                    <div class="dfcc">
                        <button class="login_btn" v-preventReClick @click="login">
                            <span class="btn-txt">登录</span>
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getToken, setToken, rememberPwd, getPwd } from "@/utils/auth.js";
export default {
    data() {
        return {
            loginForm: {
                Name: "",
                Password: "",
                GoogleCode: ""
            }, //登录表单
            rules: {
                Name: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                Password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                GoogleCode: [
                    { required: true, message: '请输入谷歌验证码', trigger: 'blur' },
                ],
            },//表单验证
            checked: false,//是否记住密码
        }
    },
    mounted() {
        if (getToken()) {
            this.$store.dispatch("user/logout");
        }
        this.$store.dispatch("tagsView/delAllViews");
        if (getPwd()) {
            this.checked = true;
            this.userInfo = JSON.parse(decodeURI(getPwd()));
            this.loginForm.Name = this.userInfo.Name;
            this.loginForm.Password = this.xor(this.userInfo.Password, "20232023");
        }
    },
    methods: {
        // 加密
        xor(val, key) {
            if (typeof key == "number") key = [key];
            let output = "";
            for (var i = 0; i < val.length; i++) {
                const c = val.charCodeAt(i);
                const k = key[i % key.length];
                output += String.fromCharCode(c ^ k);
            }
            return output;
        },
        // 登录
        login() {
            this.$refs["loginForm"].validate((valid) => {
                if (valid) {
                    //记住密码
                    if (this.checked == true) {
                        rememberPwd(encodeURI(JSON.stringify({
                            Name: this.loginForm.Name,
                            Password: this.xor(this.loginForm.Password, "20232023"),
                        })));
                    }
                    this.$store.dispatch("user/login", this.loginForm).then((res) => {
                        // this.$store.commit('permission/SET_FUNCID', 0)
                    });


                }
            })
            // this.$router.push({
            //     name: "consoleDesk",
            // });
        },
    }

}
</script>

<style lang="scss" scoped>
::v-deep.login {
    width: 100vw;
    min-height: 100vh;
    background: linear-gradient(to bottom, #f1f4f9, #dff1ff);
    display: flex;
    overflow: hidden;
    justify-content: space-around;
    align-items: center;

    .logo {
        height: 100px;

        span {
            position: relative;
            top: 20px;
            margin: 0 10px;
            display: inline-block;
            animation: bounce .6s ease infinite alternate;
            font-family: 'Titan One', cursive;
            font-size: 80px;
            color: #FFF;
            text-shadow: 0 1px 0 #CCC,
                0 2px 0 #CCC,
                0 3px 0 #CCC,
                0 4px 0 #CCC,
                0 5px 0 #CCC,
                0 6px 0 transparent,
                0 7px 0 transparent,
                0 8px 0 transparent,
                0 9px 0 transparent,
                0 10px 10px rgba(0, 0, 0, .4);
        }

        span:nth-child(2) {
            animation-delay: .1s;
        }

        span:nth-child(3) {
            animation-delay: .2s;
        }

        span:nth-child(4) {
            animation-delay: .3s;
        }

        span:nth-child(5) {
            animation-delay: .4s;
        }

        @keyframes bounce {
            100% {
                top: -20px;
                text-shadow: 0 1px 0 #CCC,
                    0 2px 0 #CCC,
                    0 3px 0 #CCC,
                    0 4px 0 #CCC,
                    0 5px 0 #CCC,
                    0 6px 0 #CCC,
                    0 7px 0 #CCC,
                    0 8px 0 #CCC,
                    0 9px 0 #CCC,
                    0 50px 25px rgba(0, 0, 0, .2);
            }
        }
    }

    .color {
        position: absolute;
        filter: blur(150px);
    }

    .color:nth-child(1) {
        top: -350px;
        left: 34%;
        width: 600px;
        height: 600px;
        background: #ff359b;
    }

    .color:nth-child(2) {
        bottom: -150px;
        left: 100px;
        width: 500px;
        height: 500px;
        background: #fffd87;
    }

    .color:nth-child(3) {
        bottom: 50px;
        right: 100px;
        width: 300px;
        height: 300px;
        background: #00dfff;
    }

    .box {
        position: relative;

        .square {
            position: absolute;
            background: rgba(255, 255, 255, .1);
            backdrop-filter: blur(5px);
            box-shadow: 0 25px 45px rgba(0, 0, 0, .1);
            border: 1px solid rgba(255, 255, 255, .5);
            border-right: 1px solid rgba(255, 255, 255, .2);
            border-bottom: 1px solid rgba(255, 255, 255, .2);
            border-radius: 10px;
            animation: animate 10s linear infinite;
            animation-delay: calc(-1s*var(--i));

        }

        .login_box {
            position: relative;
            width: 400px;
            min-height: 400px;
            background: rgba(255, 255, 255, .1);
            border-radius: 10px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            // align-items: center;
            flex-direction: column;
            backdrop-filter: blur(5px);
            box-shadow: 0 25px 45px rgba(0, 0, 0, .1);
            border: 1px solid rgba(255, 255, 255, .5);
            border-right: 1px solid rgba(255, 255, 255, .2);
            border-bottom: 1px solid rgba(255, 255, 255, .2);

            .el-form-item:last-child {
                margin-bottom: 0 !important;

                .el-checkbox__label {
                    color: #000;
                }
            }

            .title {
                width: 100%;
                text-align: center;
                font-size: 30px !important;
                font-weight: 600;
                color: #8f2c24;
                margin-bottom: 10px;
            }

            .login_btn {
                margin-top: 10px;
                height: 40px;
                width: 150px;
                position: relative;
                background-color: transparent;
                cursor: pointer;
                border: 2px solid #409EFF;
                overflow: hidden;
                border-radius: 6px;
                color: #409EFF;
                transition: all 0.5s ease-in-out;

                .btn-txt {
                    z-index: 1;
                    font-weight: 800;
                    letter-spacing: 4px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: all 0.5s ease-in-out;
                    background-color: #409EFF;
                    border-radius: 30px;
                    visibility: hidden;
                    height: 10px;
                    width: 10px;
                    z-index: -1;
                }

                &:hover {
                    box-shadow: 1px 1px 200px #409EFF;
                    color: #fff;
                    border: none;
                }

                &:hover::after {
                    visibility: visible;
                    transform: scale(100) translateX(2px);
                }
            }
        }

        @keyframes animate {

            0%,
            100% {
                transform: translateY(-40px);
            }

            50% {
                transform: translateY(40px);
            }
        }

        .square:nth-child(1) {
            top: -50px;
            right: -60px;
            width: 100px;
            height: 100px;
        }

        .square:nth-child(2) {
            top: 150px;
            left: -100px;
            width: 120px;
            height: 120px;
            z-index: 2;
        }

        .square:nth-child(3) {
            bottom: 50px;
            right: -60px;
            width: 80px;
            height: 80px;
            z-index: 2;
        }

        .square:nth-child(4) {
            bottom: -80px;
            left: 100px;
            width: 50px;
            height: 50px;
        }

        .square:nth-child(5) {
            top: -80px;
            left: 140px;
            width: 60px;
            height: 60px;
        }
    }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import emojiRegex from 'emoji-regex';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import './permission'
import "@/static/css/common.css"
import componentDemo from "@components/index.js" //挂载组件
import '@/components/Dialog/dialog.js'//拖拽
import preventReClick from './utils/clickStatefrom' // 防重复提交
import permissions from './utils/permissions' // 按钮权限
import day from "dayjs";
import scroll from 'vue-seamless-scroll'


// 首页引导插件
import intro from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式
// introjs还提供了多种主题，可以通过以下方式引入
import 'intro.js/themes/introjs-modern.css' // introjs主题
 
// 把intro.js加入到vue的prototype中，方便使用，就可以直接通过this.$intro()来调用了
Vue.prototype.$intro = intro

//svg图标
import {
	MyIcon,
} from '@/utils/iconFont'
Vue.use(preventReClick)
Vue.use(scroll)
Vue.use(permissions)
Vue.use(Antd)
Vue.component('MyIcon', MyIcon);
Vue.use(ElementUI,{ size: 'small', zIndex: 3000 });
Vue.use(componentDemo)
Vue.config.productionTip = false
Vue.prototype.dayjs=day;
Vue.prototype.validForbid = function (value) {
	value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, "");
	return value;
};

Vue.directive('clearEmoij', {
	bind(el, binding, vnode, oldVnode) {
		const regex = emojiRegex();
		const obj = el.querySelectorAll('.el-input__inner,.el-textarea__inner')[0];
		const zclearNoNum = function (e) {
			if (e.target.composing) return;
			const match = regex.exec(obj.value); // 也可以直接用正则表达式判断
			if (match) {
				/* for (let i=0;i<match.length;i++) {
					obj.value = obj.value.replace(match[i],"");
				}*/
				// obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '');
				// obj.value = obj.value.replace(/(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f\ude80-\udeff])|[\u2600-\u2B55]/g, '');
				obj.value = obj.value.replace(/(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g, '');
				/[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/
				zclearNoNum(e);
			}
			// 触发v-model的更新
			obj.dispatchEvent(new Event('input'));
		}
		const zblur = function (e) {
			zclearNoNum(e);
			setTimeout(() => {
				const match = regex.exec(obj.value);
				if (match) {
					obj.value = obj.value.replace(/(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g, '');
					zclearNoNum(e);
				}
				// 触发v-model的更新
				obj.dispatchEvent(new Event('change'));
			}, 50)
		}
		obj.onkeyup = zclearNoNum;
		obj.onblur = zblur;


		function onCompositionStart(e) {
			e.target.composing = true
		}
		function onCompositionEnd(e) {
			// console.log('按回车将字输入', e.target.value)
			e.target.composing = false
			obj.dispatchEvent(new Event('change'));
			setTimeout(() => {
				if (obj.value) {
					// obj.value = obj.value.replace(/(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g, '');
					zclearNoNum(e);
				}
				// 触发v-model的更新
				obj.dispatchEvent(new Event('change'));
			}, 50)
		}
		obj.addEventListener('compositionstart', onCompositionStart)
		obj.addEventListener('compositionend', onCompositionEnd)
	},
	update(el, binding, vnode, oldVnode) { }
})
new Vue({
	el: '#app',
	router,
	store,
	// i18n,
	mounted() {
		document.dispatchEvent(new Event("render-event"));
	},
	render: h => h(App)
})
